import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import calquesbar from "../img/captures/bar_calques.png";
import calques from "../img/captures/calques.png";
import styles from "../styles/section_calques.module.scss";

const Section_calques = () => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.section_calques, "col-12 row")}>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-2")}></div>
          <h1>{t("guidelines")}</h1>
        </div>

        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div>
            <p>
              <span>{t("guidelinecalques.corps1")}</span>
              <br />
              {t("guidelinecalques.corps2")}
            </p>
          </div>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={calques} alt="Calques" />
            </figure>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames(styles.tbsection, "col-4")}>
          <div className={classNames(styles.setting_img02)}>
            <figure>
              <img src={calquesbar} alt="calquesbar" />
            </figure>
          </div>
          <div className={classNames("col-12")}>
            <table>
              <tr>
                <th>{t("layername")}</th>
                <th>DESCRIPTION</th>
              </tr>

              <tr>
                <td>goldFoil</td>
                <td>{t("goldfoil")}</td>
              </tr>

              <tr>
                <td>silverFoil</td>
                <td>{t("silverfoil")}</td>
              </tr>
              <tr>
                <td>copperFoil</td>
                <td>{t("copperfoil")}</td>
              </tr>
              <tr>
                <td>redFoil</td>
                <td>{t("redfoil")}</td>
              </tr>
              <tr>
                <td>greenFoil</td>
                <td>{t("greenfoil")}</td>
              </tr>
              <tr>
                <td>blueFoil</td>
                <td>{t("bluefoil")}</td>
              </tr>
              <tr>
                <td>pinkFoil</td>
                <td>{t("pinkfoil")}</td>
              </tr>
              <tr>
                <td>orangeFoil</td>
                <td>{t("orangefoil")}</td>
              </tr>
              <tr>
                <td>blackFoil</td>
                <td>{t("blackfoil")}</td>
              </tr>
              <tr>
                <td>whiteFoil</td>
                <td>{t("whitefoil")}</td>
              </tr>
              <tr>
                <td>varnish</td>
                <td>{t("varnishzone")}</td>
              </tr>
              <tr>
                <td>color</td>
                <td>{t("quadri")}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_calques;
