import React from "react";
import classNames from "classnames";
import styles from "../styles/section02.module.scss";
import Menu from "./menu";
import img_f2h from "../img/captures/img_f2H.png";
import img_f2 from "../img/captures/img_f2.png";
import img_f2s from "../img/captures/img_f2S.png";
import img_f2st from "../img/captures/img_f2ST.png";
import { useTranslation } from "react-i18next";

const Section02 = () => {
  const { t } = useTranslation();
  return (
    <div id="dorure2D" className={classNames(styles.section02, "col-12 row")}>
      <div className={classNames(styles.menu, "col-3")}>
        <Menu selected={"foil"}></Menu>
      </div>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-3")}></div>
          <div className={classNames(styles.titlebis, "col-8 row")}>
            <h4>{t("Sommaire.dorure2d")}</h4>
            <h4>02</h4>
          </div>
          <div className={classNames("col-2")}></div>
        </div>

        <div className={classNames("col-3")}></div>
        <div className={classNames("col-3")}>
          <div>
            <p>
              {t("dorure2d.corps1")}
              <br />
              <br />
              {t("dorure2d.corps2")}
              <br />
              <br />
              {t("dorure2d.corps3")}
              <br />
              {t("dorure2d.corps4")} «<b>{t("dorure2d.corps5")}</b>»,
              {t("dorure2d.exemple")} : "<b>goldFoil</b>" {t("dorure2d.corps6")}
              <br />
              <br />
              {t("dorure2d.corps7")}
              <br />
              {t("dorure2d.corps8")}«<b>{t("dorure2d.corps5")}</b>»{" "}
              {t("dorure2d.corps9")}
            </p>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div className={classNames(styles.setting_imgh)}>
            <figure>
              <img src={img_f2h} alt="Couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={img_f2} alt="Hover couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgs)}>
            <figure>
              <img src={img_f2s} alt="Settings hover" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgst)}>
            <figure>
              <img src={img_f2st} alt="Settings hover" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section02;
