import React from "react";
import classNames from "classnames";
import styles from "../styles/home.module.scss";
import Navbar from "./navbar";
import SectionGeneral from "./section_general";
import SectionCalques from "./section_calques";
import SectionTeintes from "./section_teintes";
import SectionSommaire from "./section_sommaire";
import Section01 from "./section01";
import Section02 from "./section02";
import Section03 from "./section03";
import Section04 from "./section04";
import Section05 from "./section05";
import Section06 from "./section06";

const Landing = () => {
  return (
    <div className={classNames(styles.home)}>
      <Navbar></Navbar>
      <SectionGeneral></SectionGeneral>
      <SectionCalques></SectionCalques>
      <SectionTeintes></SectionTeintes>
      <SectionSommaire></SectionSommaire>
      <Section01></Section01>
      <Section02></Section02>
      <Section03></Section03>
      <Section04></Section04>
      <Section05></Section05>
      <Section06></Section06>
    </div>
  );
};

export default Landing;
