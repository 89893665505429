import React from "react";
import classNames from "classnames";
import styles from "../styles/section05.module.scss";
import Menu from "./menu";
import img_f5h from "../img/captures/img_f5H.png";
import img_f5 from "../img/captures/img_f5.png";
import img_f5s from "../img/captures/img_f5S.png";
import img_f5st from "../img/captures/img_f5ST.png";
import img_f5sd from "../img/captures/img_f5SD.png";
import { useTranslation } from "react-i18next";

const Section05 = () => {
  const { t } = useTranslation();
  return (
    <div id="vernis3D" className={classNames(styles.section05, "col-12 row")}>
      <div className={classNames(styles.menu, "col-3")}>
        <Menu selected={"varnish3d"}></Menu>
      </div>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-3")}></div>
          <div className={classNames(styles.titlebis, "col-8 row")}>
            <h4>{t("Sommaire.vernis3d")}</h4>
            <h4>05</h4>
          </div>
          <div className={classNames("col-2")}></div>
        </div>

        <div className={classNames("col-3")}></div>
        <div className={classNames("col-3")}>
          <div>
            <p>
              {t("vernis3d.corps1")}
              <br />
              <br />
              {t("vernis3d.corps2")}
              <br />
              <br />
              {t("vernis3d.corps3")}
              <br />
              {t("vernis3d.corps4")} «<b>varnish</b>» {t("vernis3d.corps5")}
              <br />
              <br />
              {t("vernis3d.corps6")}
              <br />
              {t("vernis3d.corps7")} «<b>varnish</b>» . {t("vernis3d.corps8")}
            </p>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div className={classNames(styles.setting_imgh)}>
            <figure>
              <img src={img_f5h} alt="Couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={img_f5} alt="Hover couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgs)}>
            <figure>
              <img src={img_f5s} alt="Settings hover" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgst)}>
            <figure>
              <img src={img_f5st} alt="Settings" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgsd)}>
            <figure>
              <img src={img_f5sd} alt="Settings density" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section05;
