import React from "react";
import classNames from "classnames";
import styles from "../styles/section_sommaire.module.scss";
import Menu from "./menu_sommaire";
import img from "../img/captures/img01.png";
import { useTranslation } from "react-i18next";

const Section_sommaire = () => {
  const { t } = useTranslation();
  return (
    <div
      id="sommaire"
      className={classNames(styles.section_sommaire, "col-12 row")}
    >
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-3")}></div>
          <div className={classNames(styles.titlebis, "col-8 row")}>
            <h4>{t("Sommaire.structurePDF")}</h4>
          </div>
          <div className={classNames("col-2")}></div>
        </div>

        <div className={classNames(styles.menu, "col-3")}>
          <Menu></Menu>
        </div>
        <div className={classNames("col-3")}>
          <div>
            <ul>
              <li>
                <div className={classNames(styles.list)}>
                  <p className={classNames(styles.number)}>6</p>{" "}
                  <p>{t("Sommaire.decoupe")}</p>
                </div>
              </li>
              <li>
                <div className={classNames(styles.list)}>
                  <p className={classNames(styles.number)}>5</p>{" "}
                  <p>{t("Sommaire.vernis3d")}</p>
                </div>
              </li>
              <li>
                <div className={classNames(styles.list)}>
                  <p className={classNames(styles.number)}>4</p>{" "}
                  <p>{t("Sommaire.vernis2d")}</p>
                </div>
              </li>
              <li>
                <div className={classNames(styles.list)}>
                  <p className={classNames(styles.number)}>3</p>{" "}
                  <p>{t("Sommaire.dorure3d")}</p>
                </div>
              </li>
              <li>
                <div className={classNames(styles.list)}>
                  <p className={classNames(styles.number)}>2</p>{" "}
                  <p>{t("Sommaire.dorure2d")}</p>
                </div>
              </li>
              <li>
                <div className={classNames(styles.list)}>
                  <p className={classNames(styles.number)}>1</p>{" "}
                  <p>{t("Sommaire.color")}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={img} alt="Packaging" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_sommaire;
