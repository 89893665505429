import React from "react";
import classNames from "classnames";
import styles from "../styles/section04.module.scss";
import Menu from "./menu";
import img_f4h from "../img/captures/img_f4H.png";
import img_f4 from "../img/captures/img_f4.png";
import img_f4s from "../img/captures/img_f4S.png";
import img_f4st from "../img/captures/img_f4ST.png";
import { useTranslation } from "react-i18next";

const Section04 = () => {
  const { t } = useTranslation();
  return (
    <div id="vernis2D" className={classNames(styles.section04, "col-12 row")}>
      <div className={classNames(styles.menu, "col-3")}>
        <Menu selected={"varnish"}></Menu>
      </div>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-3")}></div>
          <div className={classNames(styles.titlebis, "col-8 row")}>
            <h4>{t("Sommaire.vernis2d")}</h4>
            <h4>04</h4>
          </div>
          <div className={classNames("col-2")}></div>
        </div>

        <div className={classNames("col-3")}></div>
        <div className={classNames("col-3")}>
          <div>
            <p>
              {t("vernis2d.corps1")}
              <br />
              <br />
              {t("vernis2d.corps2")}
              <br />
              <br />
              {t("vernis2d.corps3")}
              <br />
              {t("vernis2d.corps4")} «<b>varnish</b>» {t("vernis2d.corps5")}
              <br />
              <br />
              {t("vernis2d.corps6")}
              <br />
              {t("vernis2d.corps7")}«<b>varnish</b>».
            </p>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div className={classNames(styles.setting_imgh)}>
            <figure>
              <img src={img_f4h} alt="Couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={img_f4} alt="Hover couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgs)}>
            <figure>
              <img src={img_f4s} alt="Settings hover" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgst)}>
            <figure>
              <img src={img_f4st} alt="Settings hover" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section04;
