import React from "react";
import classNames from "classnames";
import styles from "../styles/section06.module.scss";
import Menu from "./menu";
import img_f6h from "../img/captures/img_f6H.png";
import img_f6 from "../img/captures/img_f6.png";
import img_f6s from "../img/captures/img_f6ST.png";
import { useTranslation } from "react-i18next";

const Section06 = () => {
  const { t } = useTranslation();
  return (
    <div
      id="découpesetplis"
      className={classNames(styles.section06, "col-12 row")}
    >
      <div className={classNames(styles.menu, "col-3")}>
        <Menu selected={"cut"}></Menu>
      </div>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-3")}></div>
          <div className={classNames(styles.titlebis, "col-8 row")}>
            <h4>{t("Sommaire.decoupe")}</h4>
            <h4>06</h4>
          </div>
          <div className={classNames("col-2")}></div>
        </div>

        <div className={classNames("col-3")}></div>
        <div className={classNames("col-3")}>
          <div>
            <p>
              {t("decoupe.corps1")}«<b>CutContour</b>»
              <br />
              <br />
              {t("decoupe.corps2")}«<b>Crease</b>» {t("decoupe.corps3")}
            </p>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div className={classNames(styles.setting_imgh)}>
            <figure>
              <img src={img_f6h} alt="Couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={img_f6} alt="Hover couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgs)}>
            <figure>
              <img src={img_f6s} alt="Settings hover" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section06;
