import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles/section_general.module.scss";
import exportfonction from "../img/captures/exportfonction.png";
import { isharrisbruno } from "../checkharrisbruno";

const Section_general = () => {
  const [isVisibleExport, setSearch] = useState(false);
  const showExport = () => setSearch((isVisibleExpor) => !isVisibleExpor);

  const { t } = useTranslation();
  return (
    <div className={classNames(styles.section_general, "col-12 row")}>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.content, "col-12 row")}>
          <div className={classNames(styles.title, "col-12 row ")}>
            <div className={classNames("col-2")}></div>
            <h1>{t("guidelines")}</h1>
          </div>
          <div className={classNames("col-12 row")}>
            <div className={classNames("col-2")}></div>
            <div className={classNames("col-3")}>
              <p>
                <span>
                  {!isharrisbruno()
                    ? t("general.corps1")
                    : t("general.corps1hb")}
                </span>
                <br />
                <br />
                {!isharrisbruno() ? t("general.corps2") : t("general.corps2hb")}
                <br />
                <br />

                {!isharrisbruno() ? t("general.corps3") : t("general.corps3hb")}

                <br />
                <br />
                {t("general.corps4")}

                <br />
                <br />
                {t("general.corps5")}
              </p>
            </div>
            <div className={classNames("col-2")}></div>
            <div className={classNames("col-3")}>
              <h3>{t("general.corps6")}</h3>
              <ul>
                <li>
                  <strong>{t("Foil")}</strong>
                </li>
                <li>{t("Sommaire.dorure2d")}</li>
                <li>{t("Sommaire.dorure3d")}</li>
              </ul>
              <ul>
                <li>
                  <strong>{t("Varnish")}</strong>
                </li>
                <li>{t("Sommaire.vernis2d")}</li>
                <li>{t("Sommaire.vernis3d")}</li>
              </ul>
              <ul>
                <li>
                  <strong>{t("Colorgen")}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.content, "col-12 row")}>
          <div className={classNames("col-2")}></div>
          <div className={classNames("col-3")}>
            <p>
              {!isharrisbruno() ? t("general.corps7") : t("general.corps7hb")}
              <br />
              <br />
              {t("general.corps8")}
              <br />
              <br />
              {t("general.corps9")}
            </p>
            <h2 className={classNames(styles.box, styles.margintop)}>
              {t("general.corps17")}
            </h2>
            <p>
              {!isharrisbruno() ? t("general.corps18") : t("general.corps18hb")}
            </p>
          </div>
          <div className={classNames("col-2")}></div>
          <div className={classNames("col-3")}>
            <h2 className={classNames(styles.box)}>{t("general.corps10")} :</h2>
            <ul className={classNames(styles.none)}>
              <li>
                <p>
                  <span>- {t("general.corps11")},</span> <br />
                  {t("general.corps12")}
                </p>
              </li>
              <li>
                <p>
                  <span>- {t("general.corps13")},</span> <br />
                  {t("general.corps14")}
                </p>
              </li>
              <li>
                <p>
                  <span>- {t("general.corps15")},</span> <br />
                  {t("general.corps16")}
                </p>
              </li>
              <li>
                <p>
                  <span>- {t("general.corps20")},</span> <br />
                  {t("general.corps21")}
                </p>
              </li>
              <li>
                <p>
                  <span>- {t("general.corps22")},</span> <br />
                  {t("general.corps23")}{" "}
                  <u onClick={showExport}>{t("general.linkimg")}</u>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={classNames(
            styles.modalimg,
            !isVisibleExport ? styles.notvisibleexport : ""
          )}
        >
          <div
            className={classNames(styles.onclick)}
            onClick={showExport}
          ></div>
          <div className={classNames(styles.modal)}>
            <img
              className={classNames(styles.image)}
              src={exportfonction}
              alt="Fonction d'export"
            />
            <svg
              onClick={showExport}
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
            >
              <g id="close" transform="translate(-815 -260)">
                <path
                  id="Tracé_60"
                  data-name="Tracé 60"
                  d="M815,260h24v24H815Z"
                  fill="none"
                />
                <path
                  id="Tracé_61"
                  data-name="Tracé 61"
                  d="M838.513,267.236l-1.865-1.865-7.392,7.392-7.392-7.392L820,267.236l7.392,7.392L820,282.02l1.865,1.865,7.392-7.392,7.392,7.392,1.865-1.865-7.392-7.392Z"
                  transform="translate(-2.257 -2.257)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_general;
