import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import teintesbar from "../img/captures/bar_teintes.png";
import teintes from "../img/captures/teintes.png";
import styles from "../styles/section_teintes.module.scss";

const Section_teintes = () => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.section_teintes, "col-12 row")}>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-2")}></div>
          <h1>{t("guidelines")}</h1>
        </div>

        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div>
            <p>
              <span>{t("guidelineteintes.corps1")}</span>
              <br />
              {t("guidelineteintes.corps2")}
            </p>
          </div>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={teintes} alt="Teintes" />
            </figure>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames(styles.tbsection, "col-4")}>
          <div className={classNames(styles.setting_img02)}>
            <figure>
              <img src={teintesbar} alt="Teintesbar" />
            </figure>
          </div>
          <div className={classNames("col-12")}>
            <table>
              <tr>
                <th>{t("guidelineteintes.corps3")}</th>
                <th>{t("guidelineteintes.corps4")}</th>
              </tr>

              <tr>
                <td>CutContour</td>
                <td>{t("cutcontour")}</td>
              </tr>

              <tr>
                <td>Crease</td>
                <td>{t("crease")}</td>
              </tr>
              <tr>
                <td>goldFoil</td>
                <td>{t("goldfoil")}</td>
              </tr>
              <tr>
                <td>silverFoil</td>
                <td>{t("silverfoil")}</td>
              </tr>
              <tr>
                <td>copperFoil</td>
                <td>{t("copperfoil")}</td>
              </tr>
              <tr>
                <td>redFoil</td>
                <td>{t("redfoil")}</td>
              </tr>
              <tr>
                <td>greenFoil</td>
                <td>{t("greenfoil")}</td>
              </tr>
              <tr>
                <td>blueFoil</td>
                <td>{t("bluefoil")}</td>
              </tr>
              <tr>
                <td>pinkFoil</td>
                <td>{t("pinkfoil")}</td>
              </tr>
              <tr>
                <td>orangeFoil</td>
                <td>{t("orangefoil")}</td>
              </tr>
              <tr>
                <td>whiteFoil</td>
                <td>{t("blackfoil")}</td>
              </tr>
              <tr>
                <td>blackFoil</td>
                <td>{t("whitefoil")}</td>
              </tr>
              <tr>
                <td>varnish</td>
                <td>{t("varnishzone")}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_teintes;
