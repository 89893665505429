import React from "react";
import classNames from "classnames";
import styles from "../styles/section01.module.scss";
import Menu from "./menu";
import img_f1h from "../img/captures/img_f1H.png";
import img_f1 from "../img/captures/img_f1.png";
import img_f1s from "../img/captures/img_f1S.png";
import { useTranslation } from "react-i18next";

const Section01 = () => {
  const { t } = useTranslation();
  return (
    <div id="couleurs" className={classNames(styles.section01, "col-12 row")}>
      <div className={classNames(styles.menu, "col-3")}>
        <Menu selected={"color"}></Menu>
      </div>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-3")}></div>
          <div className={classNames(styles.titlebis, "col-8 row")}>
            <h4>{t("Sommaire.color")}</h4>
            <h4>01</h4>
          </div>
          <div className={classNames("col-2")}></div>
        </div>

        <div className={classNames("col-3")}></div>
        <div className={classNames("col-3")}>
          <div>
            <p>
              {t("colors.corps1")} "<b>color</b>" {t("colors.corps2")}
            </p>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div className={classNames(styles.setting_imgh)}>
            <figure>
              <img src={img_f1h} alt="Couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={img_f1} alt="Hover couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgs)}>
            <figure>
              <img src={img_f1s} alt="Settings hover" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section01;
