import React from "react";
import classNames from "classnames";
import styles from "../styles/section03.module.scss";
import Menu from "./menu";
import img_f3h from "../img/captures/img_f3H.png";
import img_f3 from "../img/captures/img_f3.png";
import img_f3s from "../img/captures/img_f3S.png";
import img_f3st from "../img/captures/img_f3ST.png";
import img_f3sd from "../img/captures/img_f3SD.png";
import { useTranslation } from "react-i18next";

const Section03 = () => {
  const { t } = useTranslation();
  return (
    <div id="dorure3D" className={classNames(styles.section03, "col-12 row")}>
      <div className={classNames(styles.menu, "col-3")}>
        <Menu selected={"foil3d"}></Menu>
      </div>
      <div className={classNames(styles.section, "col-12 row")}>
        <div className={classNames(styles.title, "col-12 row")}>
          <div className={classNames("col-3")}></div>
          <div className={classNames(styles.titlebis, "col-8 row")}>
            <h4>{t("Sommaire.dorure3d")}</h4>
            <h4>03</h4>
          </div>
          <div className={classNames("col-2")}></div>
        </div>

        <div className={classNames("col-3")}></div>
        <div className={classNames("col-3")}>
          <div>
            <p>
              {t("dorure3d.corps1")}
              <br />
              <br />
              {t("dorure3d.corps2")}
              <br />
              <br />
              {t("dorure3d.corps3")}
              <br />
              {t("dorure3d.corps4")} «<b>{t("dorure3d.corps5")}</b>»
              {t("dorure3d.corps6")}
              <br />
              <br />
              {t("dorure3d.corps7")}
              <br />
              {t("dorure3d.corps8")}«<b>{t("dorure3d.corps5")}</b>».{" "}
              {t("dorure3d.corps9")}
            </p>
          </div>
        </div>
        <div className={classNames("col-2")}></div>
        <div className={classNames("col-3")}>
          <div className={classNames(styles.setting_imgh)}>
            <figure>
              <img src={img_f3h} alt="Couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_img01)}>
            <figure>
              <img src={img_f3} alt="Hover couleur" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgs)}>
            <figure>
              <img src={img_f3s} alt="Settings hover" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgst)}>
            <figure>
              <img src={img_f3st} alt="Settings" />
            </figure>
          </div>
          <div className={classNames(styles.setting_imgsd)}>
            <figure>
              <img src={img_f3sd} alt="Settings density" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section03;
